import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

export function useElementPlus(app) {
  if (!app) return
  app.use(ElementPlus, {
    locale: zhCn
  })
}
