import { createRouter, createWebHistory } from 'vue-router'
import routeList from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout(layout => {
  return import('@/layout/' + layout + '.vue')
})

let base = '/'

console.log(routeList)
export const routes = [
  {
    path: '/',
    component: RouterLayout,
    children: routeList
  }
]

export function useRouter(app) {
  if (!app) return
  const routeInstance = createRouter({
    history: createWebHistory(base),
    routes: routes
  })
  app.use(routeInstance)
}
