import { ref, computed } from 'vue'
import axios from '@/plugin/axios'
import { isMicroApp } from '@/util/env'

export let loading = ref(false)

export const fetch = isMicroApp ? window.$globalUtil.axios : axios

export const isMobile = computed(() => {
  if (window.innerWidth > 700) {
    return false
  }
  return true
})

export const isMiniprogram = ref(false)

wx.miniProgram.getEnv(function (res) {
  if (res.miniprogram) {
    isMiniprogram.value = true
  }
})
