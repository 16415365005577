<template>
  <div class="uploadBox">
    <div class="multipleUpload">
      <div class="dragArea image-uploader list-group" @change="handleDraggable" :list="localImageList">
        <span v-for="(item, index) in localImageList" :key="index" class="imageItem">
          <img class="image" v-if="item" :src="item" alt="无图片" />
          <span :class="`imageItemActions ${isMobile ? 'isMobile' : ''} `">
            <span class="imageAction" @click="handlePictureCardPreview(item)">
              <el-icon><View /></el-icon>
            </span>
            <span class="imageAction" @click="handleRemove(index)" v-if="!isMiniprogram">
              <el-icon><Delete /></el-icon>
            </span>
          </span>
        </span>
        <div class="el-icon-plus uploadIcon" v-if="limit > localImageList.length && !isMiniprogram">
          <el-icon><Upload /></el-icon>
          <input type="file" ref="input" accept="image/*" @change="handleChange" :multiple="limit > 1" />
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="true" v-model="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="预览地址" style="width: 100%; max-height: 60vh" />
    </el-dialog>
  </div>
</template>

<script>
import { uploadImage } from '@/provider/upload'
import { Delete, View, Upload } from '@element-plus/icons-vue'
import { isMobile } from '@/provider/sys'
import { isMiniprogram } from '@/provider/sys'
export default {
  name: 'bm-upload',
  components: { Delete, View, Upload },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: 'system'
    },
    value: {
      type: [Array, String],
      default: []
    },
    isEncrypt: {
      type: Boolean,
      default: false
    },
    reduceResolution: {
      type: Boolean,
      default: false
    },
    format: {
      type: Array,
      default() {
        return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp']
      }
    }
  },
  watch: {
    value(val) {
      if (typeof val === 'string') {
        if (val) {
          this.localImageList = [val]
        } else {
          this.localImageList = []
        }
      } else {
        this.localImageList = val
      }
    }
  },
  data() {
    return {
      // 图片的地址
      localImageList: [],
      // 弹窗的显示和弹窗图片地址
      dialogVisible: false,
      dialogImageUrl: '',
      isMobile,
      isMiniprogram
    }
  },
  methods: {
    handleChange(e) {
      const files = e.target.files
      if (!files) {
        return
      }
      const postFiles = Array.prototype.slice.call(files)
      postFiles.forEach(item => {
        this.post(item)
      })
      this.$refs.input.value = null
    },
    // 上传图片
    post(file) {
      if (this.format.length) {
        const _file_format = file.name.split('.').pop().toLocaleLowerCase()
        const checked = this.format.some(item => item.toLocaleLowerCase() === _file_format)
        if (!checked) {
          this.$message.error('图片格式有误!')
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '上传中，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        uploadImage({
          file,
          type: this.type,
          isEncrypt: this.isEncrypt,
          reduceResolution: this.reduceResolution
        })
          .then(res => {
            console.log('>>>>>>> res', res)
            if (this.localImageList.length >= this.limit) {
              return
            }
            this.localImageList.push(res)
            this.handleUpdate(this.localImageList)
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    // 预览图片，出现弹窗，然后把值赋值给弹窗的img图片
    handlePictureCardPreview(val) {
      this.dialogVisible = true
      this.dialogImageUrl = val
    },
    // 多张图片的时候，删除图片
    handleRemove(index) {
      this.localImageList.splice(index, 1)
      this.handleUpdate(this.localImageList)
    },
    handleDraggable() {
      this.handleUpdate(this.localImageList)
    },
    handleUpdate(val) {
      if (this.limit === 1) {
        this.$emit('input', val[0] || '')
        this.$emit('change', val[0] || '')
        return
      }
      this.$emit('input', val || [])
      this.$emit('change', val || [])
    }
  }
}
</script>
<style lang="less" scoped>
.uploadBox {
  max-width: 100%;
  .image-uploader {
    // max-width: 100vw;
    // overflow: hidden;
    // overflow-x: auto;
    max-width: 100vw;
    // display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 6px;
    position: relative;
    .imageItem {
      position: relative;
      display: inline-block;
      height: 100px;
      top: 0;
      // position: inherit;
      // display: inline-flex;
      margin-right: 10px;
    }

    .image {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }
    .imageItemActions {
      width: 100px;
      height: 100px;
      position: absolute;

      left: 0;
      top: 0;
      cursor: default;
      line-height: 100px;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.3);
      transition: opacity 0.3s;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      &:hover {
        opacity: 1;
      }
      &.isMobile {
        opacity: 1;
      }
      .imageAction {
        cursor: pointer;
      }
    }
  }

  .uploadIcon {
    width: 100px;
    height: 100px;
    display: inline-block;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    line-height: 100px;
    font-size: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
  }
}
.multipleUpload {
  display: flex;
  flex-wrap: wrap;
}
</style>
