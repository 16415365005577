import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'
import { ElMessage } from 'element-plus'

let config = {
  baseURL: process.env.VUE_APP_apiBaseUrl || '',
  timeout: 60 * 1000,
  withCredentials: false
}

let instance = axios.create(config)

instance.interceptors.request.use(
  config => {
    if (localStorage['X-Commercial-Access-Token']) {
      config.headers['X-Commercial-Access-Token'] = localStorage['X-Commercial-Access-Token']
    }
    if (localStorage['Bm-Member-Token']) {
      config.headers['Bm-Member-Token'] = localStorage['Bm-Member-Token']
    }
    config.headers['Tenant-Id'] = config.headers['Tenant-Id'] || localStorage.tenantId || 1
    config.headers['Bm-Area-Id'] = config.headers['Bm-Area-Id'] || localStorage.areaId || -1
    if (config.params) {
      Reflect.deleteProperty(config.params, 'total')
      Reflect.deleteProperty(config.params, 'isFinish')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.headers['X-Commercial-Refresh-Token']) {
      localStorage['X-Commercial-Access-Token'] = response.headers['X-Commercial-Refresh-Token']
    }
    if (response.data.code === 0) {
      logAxiosSuccess(response)
      if (response.data.list) {
        let page = response.data.page
        if (page) {
          page.isFinish = page.page * page.size >= page.total
        }
        return { page, list: response.data.list, other: response.data.other }
      }
      return response.data.data || response.data
    }
    // token无效
    else if ([1000, 2001, 2002, 2003, 2004, 2005].includes(response.data.code)) {
      handleTokenExpire(response)
      logAxiosError(response)
      return Promise.reject(response.data)
    } else {
      logAxiosError(response)
      return Promise.reject(response.data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

window.$globalUtil = {}
window.$globalUtil.axios = instance

export default instance

function handleTokenExpire(response) {
  ElMessage.error(response.data.msg || response.data)
  setTimeout(() => {
    location.replace('/account/login')
  }, 100)
}
