<template>
  <div class="uploadBox">
    <div class="multipleUpload">
      <div class="el-icon-plus uploadIcon">
        <div class="title">点击上传</div>
        <div class="icon">
          <el-icon><Upload /></el-icon>
        </div>
        <img class="image" v-if="localImage" :src="localImage" alt="无图片" />
        <input type="file" ref="input" accept="image/*" @change="handleChange" />
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" v-model="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="预览地址" style="width: 100%; max-height: 60vh" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from '@element-plus/icons-vue'
export default {
  name: 'bm-upload',
  components: { Upload },
  props: {
    value: {
      type: String,
      default: ''
    },
    format: {
      type: Array,
      default() {
        return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp']
      }
    }
  },
  watch: {
    value(val) {
      this.localImage = val
    }
  },
  data() {
    return {
      // 图片的地址
      localImage: '',
      // 弹窗的显示和弹窗图片地址
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  methods: {
    handleChange(e) {
      const files = e.target.files
      if (!files) {
        return
      }
      const postFiles = Array.prototype.slice.call(files)
      postFiles.forEach(item => {
        this.post(item)
      })
      this.$refs.input.value = null
    },
    // 上传图片
    post(file) {
      if (this.format.length) {
        const _file_format = file.name.split('.').pop().toLocaleLowerCase()
        const checked = this.format.some(item => item.toLocaleLowerCase() === _file_format)
        if (!checked) {
          this.$message.error('图片格式有误!')
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '上传中，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        window.$globalUtil
          .uploadImage({
            file,
            type: this.type,
            isEncrypt: this.isEncrypt,
            reduceResolution: this.reduceResolution
          })
          .then(res => {
            console.log('>>>>>>> res', res)
            this.localImage = res
            this.handleUpdate(this.localImage)
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    },

    handleUpdate(val) {
      this.$emit('change', val)
    }
  },
  mounted() {
    this.localImage = this.value
  }
}
</script>
<style lang="less" scoped>
.uploadBox {
  cursor: pointer;
  .uploadIcon {
    cursor: pointer;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding-top: 10px;
    .title {
      font-size: 16px;
      width: 100%;
    }
    .icon {
      font-size: 30px;
    }
    .image {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100px;
      height: 100px;
      z-index: 10;
    }
    input {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      z-index: 20;
    }
  }
}
.multipleUpload {
  display: flex;
  flex-wrap: wrap;
}
</style>
