const handleBackground = (el, bind) => {
  el.style.backgroundImage = `url(${bind.value})`
  el.style.backgroundSize = `contain`
  el.style.backgroundRepeat = `no-repeat`
  el.style.backgroundPosition = `center`
  if (bind.modifiers.cover) {
    el.style.backgroundSize = `cover`
  }
  if (bind.modifiers.contain) {
    el.style.backgroundSize = `contain`
  }
  if (bind.modifiers.center) {
    el.style.backgroundSize = `center`
  }
}

export const useDirective = app => {
  app.directive('bg', {
    mounted: handleBackground,
    updated: handleBackground
  })
  app.directive('drag', {
    mounted: handleDrag,
    updated: handleDrag
  })
  app.directive('resize', {
    mounted: handleResize
  })
  app.directive('auth', {
    mounted: handleAuth,
    updated: handleAuth
  })
}

function handleDrag(el, bind) {
  el.onmousedown = e => {
    let x = 0
    let y = 0
    let disX = e.clientX - el.offsetLeft
    let disY = e.clientY - el.offsetTop
    document.onmousemove = e => {
      x = e.clientX - disX
      y = e.clientY - disY
      el.style.left = x + 'px'
      el.style.top = y + 'px'
    }
    el.onmouseup = e => {
      document.onmousemove = null
      document.onmouseup = null
      if (x) {
        bind.value.x = x
      }
      if (y) {
        bind.value.y = y
      }
    }
  }
}
function handleResize(el, bind) {
  el.onmousedown = e => {
    e.stopPropagation()
    let x = 0
    let y = 0
    let disX = e.clientX - el.offsetLeft
    let disY = e.clientY - el.offsetTop
    document.onmousemove = e => {
      x = e.clientX - disX
      y = e.clientY - disY
      bind.value.width = x + 6
      bind.value.height = y + 6
      el.style.left = x + 'px'
      el.style.top = y + 'px'
    }
    el.onmouseup = e => {
      document.onmousemove = null
      document.onmouseup = null
    }
  }
}
function handleAuth(el, bind) {
  if (bind && bind.value) {
    const userAuthList = JSON.parse(localStorage.userAuthList)
    const authCodeList = bind.value.split(',')
    if (!userAuthList.some(i => authCodeList.includes(i))) {
      if (el instanceof HTMLElement) {
        el.remove()
      }
    }
  }
}
