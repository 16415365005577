import { createApp } from 'vue'
import App from './App.vue'
import { projectId } from '@/util/env'

export let app = createApp(App)

const { useRouter } = require('@/plugin/router')
const { useDirective } = require('@/plugin/directive')
const { useElementPlus } = require('@/plugin/element')
const { useComponent } = require('@/plugin/component')

useRouter(app)
useDirective(app)
useElementPlus(app)
useComponent(app)

app.mount(`#microApp-${projectId}`)
