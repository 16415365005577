<template lang="pug">
router-view

</template>

<script setup>
import { provide } from 'vue'
import { useRouter } from 'vue-router'
import { loading } from '@/provider/sys'
import axios from '@/plugin/axios'
import { ElMessage, ElMessageBox } from 'element-plus'
const router = useRouter()

provide('global', {
  fetch: axios,
  router,
  loading,
  message: ElMessage,
  ElMessageBox
})
</script>

<style lang="less">
@import '~@/style/global/reset.less';
@import '~@/style/global/global.less';
</style>
