<template>
  <div class="popoverContainer">
    <el-tooltip class="popoverContent" placement="top">
      <template #content>
        <slot></slot>
      </template>
      <i class="questionIcon el-icon-question"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  mounted() {}
}
</script>

<style lang="less" scoped>
.popoverContent {
  display: inline-block;
  margin: 0 10px;
}
.popoverContainer {
  display: inline-block;
}
.questionIcon {
  font-size: 20px;
  color: #2980b9;
  vertical-align: middle;
}
</style>
