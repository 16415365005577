import ImageCompressor from 'compressorjs'
import SparkMD5 from 'spark-md5'
import fetch from '@/plugin/axios'
import { log } from '@/util/log'

const fileAccept = {
  imageAll: 'image/*',
  image: 'image/jpeg,image/jpg,image/png',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export function pickImageList({ count = 9, type = 'review' }) {
  return new Promise((resolve, reject) => {
    let input = document.querySelector('#imageUploader')
    if (!input) {
      input = document.createElement('input')
      input.setAttribute('id', 'imageUploader')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', fileAccept.image)
      input.setAttribute('multiple', true)
      input.style = 'width:0;height:0;display:none;'
      document.body.appendChild(input)
      input.addEventListener('change', e => {
        if (e.target.files.length > count) {
          input.remove()
          reject({ msg: `不能选择超过${count}个文件` })
        } else {
          Promise.all(
            [...e.target.files].map(file => {
              return uploadImage({ file, type })
            })
          )
            .then(res => {
              resolve(res)
            })
            .finally(() => {
              input.remove()
            })
        }
      })
    }
    input.click()
  })
}

export function uploadImage({ file, type = 'review', isCompress = true, isEncrypt = false, reduceResolution = false }) {
  if (!file instanceof File) {
    return Promise.reject({ msg: '文件类型错误' })
  }
  let targetFile
  return compressImage({ file, reduceResolution })
    .then(newFile => {
      targetFile = newFile
      return getFileHash(newFile)
    })
    .then(fileHash => {
      let url = ''
      // 商家资质，直传后加水印
      if (type === 'businessLicense') {
        let formData = new FormData()
        formData.append('photoFile', targetFile)
        formData.append('fileName', fileHash)
        formData.append('md5Name', fileHash)
        formData.append('isEncrypt', isEncrypt ? 1 : 0)
        return fetch.post('/boom-center-file-service/uploadOss/watermark/photo', formData).then(res => {
          if (isEncrypt) {
            return getFileUrlWidthSignature(res)
          } else {
            return res
          }
        })
      } else {
        return fetch
          .get('/boom-center-file-service/uploadOss/signature', {
            params: {
              type,
              key: fileHash
            }
          })
          .then(res => {
            if (res.url) {
              log('文件已存在', res.url)
              return res.url.replace('https://file.mall.tanchi.shop/', 'https://file.cdn.tanchi.shop/')
            } else {
              let formData = new FormData()
              formData.append('key', res.dir + fileHash)
              formData.append('policy', res.policy)
              formData.append('OSSAccessKeyId', res.accessId)
              formData.append('success_action_status', '200')
              formData.append('signature', res.signature)
              formData.append('file', targetFile)
              url += 'https://file.cdn.tanchi.shop/' + res.dir + fileHash
              return fetch
                .post(getOssHostUrl(res.host), formData)
                .then(() => {
                  if (targetFile.type === 'image/gif') {
                    url += '?x-oss-process=style/gif_600'
                  }
                  return url
                })
                .catch(err => {
                  return url
                })
            }
          })
      }
    })
}

function compressImage({ file, reduceResolution }) {
  if (!file) return
  return new Promise((resolve, reject) => {
    if (file.type === 'image/gif') {
      resolve(file)
      return
    }
    let ImageCompressorConfig = {
      checkOrientation: true
    }
    if (reduceResolution) {
      ImageCompressorConfig.maxWidth = 1080
    }
    new ImageCompressor(file, {
      ...ImageCompressorConfig,
      success: res => {
        const beforeSize =
          file.size > 1048576
            ? Number(file.size / 1024 / 1024).toFixed(2) + 'Mb'
            : Number(file.size / 1024).toFixed(2) + 'Kb'
        const afterSize =
          res.size > 1048576
            ? Number(res.size / 1024 / 1024).toFixed(2) + 'Mb'
            : Number(res.size / 1024).toFixed(2) + 'Kb'
        log(`压缩前：${beforeSize}`)
        const r = Number(((file.size - res.size) / file.size) * 100).toFixed(2) + '%'
        log(`压缩后：${afterSize}，压缩率${r}`)
        resolve(res)
      },
      error: err => {
        log('图片压缩出现错误', err)
        reject(err)
      }
    })
  })
}

function getFileHash(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      const hash = SparkMD5.hashBinary(e.target.result)
      resolve(hash)
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsBinaryString(file)
  })
}

function getFileUrlWidthSignature(url) {
  url = url.replace('http://bm-mall-disk.oss-cn-shenzhen.aliyuncs.com/', '')
  url = url.replace('https://disk.mall.tanchi.shop/', '')
  url = url.split('?')[0]
  return fetch.get('/boom-center-file-service/uploadOss/uploadingFile', {
    params: {
      fileUrl: url
    }
  })
}

function getOssHostUrl(host) {
  return (
    window.location.protocol +
    host.replace('file.cdn.tanchi.shop', 'file.mall.tanchi.shop').replace('http:', '').replace('https:', '')
  )
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}
