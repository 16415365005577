<template lang="pug">
teleport(to="body")
  transition(name="dialogBackground")
    .dialogContainerBackground(v-if="visible" @click="close")
  transition(@after-leave="afterLeave" name="dialogAni")
    el-card.dialog(v-if="visible")
      slot(ref="slotRef")
</template>
<script setup>
import { ref, watch, computed, onMounted } from 'vue'
const props = defineProps({
  modelValue: Boolean
})
const emit = defineEmits(['update:modelValue', 'close'])
const slotRef = ref(null)
const visible = ref(false)
watch(
  () => props.modelValue,
  val => {
    visible.value = val
  }
)

function afterLeave() {
  emit('close')
}

function close() {
  visible.value = false
  emit('update:modelValue', false)
}

onMounted(() => {
  if (props.modelValue) {
    visible.value = true
  }
})
</script>
<style scoped lang="less">
.dialogAni-leave-active,
.dialogAni-enter-active {
  transition: margin-top 0.3s ease;
}
.dialogAni-leave-to,
.dialogAni-enter-from {
  margin-top: 100vh;
}

.dialogAni-leave-from,
.dialogAni-enter-to {
  margin-top: 0px;
}
.dialog {
  padding: 16px;
  position: absolute;
  left: calc((100vw - 60vw) / 2);
  top: calc((100vh - 70vh) / 2);
  overflow: overlay;
  max-height: 70vh;
  width: 60vw;
  z-index: 24;
  background-color: white;
  opacity: 1;
}
.dialogBackground-leave-active,
.dialogBackground-enter-active {
  transition: opacity 1s ease;
}
.dialogBackground-leave-to,
.dialogBackground-enter-from {
  opacity: 1;
}
.dialogBackground-leave-to,
.dialogBackground-enter-from {
  opacity: 0;
}
.dialogContainerBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 23;
}
.clickable {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 40vh;
  z-index: 24;
  opacity: 0;
}
.dialogContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 24;
}
</style>
