const moduleList = require.context('@/component/common', false, /\.vue$/)

export function useComponent(app) {
  moduleList.keys().forEach(fileName => {
    let moduleName = fileName.substring(2, fileName.length - 4)
    moduleName = 'bm' + moduleName.substring(0, 1).toUpperCase() + moduleName.substring(1, moduleName.length)
    moduleList[moduleName] = moduleList(fileName).default
    app.component(moduleName, moduleList[moduleName])
  })
}
