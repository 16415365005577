<template>
  <el-tag :type="tagMap[classify]?.[status]?.type">
    {{ tagMap[classify]?.[status]?.text }}
  </el-tag>
</template>
<script setup>
const props = defineProps({
  classify: {
    type: String,
    defualt: () => 'audit'
  },
  status: {
    type: String,
    defualt: () => '1'
  }
})

const tagMap = {
  audit: {
    1: {
      text: '审核中',
      type: ''
    },
    2: {
      text: '审核通过',
      type: 'success'
    },
    3: {
      text: '已驳回',
      type: 'danger'
    },
    4: {
      text: '已撤销',
      type: 'danger'
    }
  }
}
</script>
<style lang="scss" scoped></style>
